import React from 'react'

import './Navigation.css'

const Navigation = () => {

    return (
        <div className='Navigation'>
        <div className='Logo'>
        WollyBooly
        </div>

            <div className='CenterMenu'>
               Center Menu
            </div>

                    <div className='RightSideMenu'>
                RightSideMenu
            </div>


        </div>
    )
}

export default Navigation;