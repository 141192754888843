import React from 'react';

const GreenDelivery = () => {

    return (
        <>
            our GreenDelivery description and story
        </>
    )
}

export default GreenDelivery