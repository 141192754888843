import React from 'react';


const ProductList = () => {

    return (
        <>all products</>
    )
}

export default ProductList;