import React from 'react'

const QuickFactsInfoLeftSide = ({src}) => {


    return (
        <div className="quickFactsInfoLeftSide">
            <img src={src}/>
        </div>
    )

}

export default QuickFactsInfoLeftSide